import React, { useEffect } from 'react';
import twitter from '../Assets/Images/Icons/twitter.png';
import telegram from '../Assets/Images/Icons/telegram.png';
import medium from '../Assets/Images/Icons/medium.png';
import { Container, Button } from 'react-bootstrap';
import WOW from 'wowjs';

const Header = () => {

  useEffect(() => {
    const wow = new WOW.WOW({
      boxClass: 'wow', // default
      animateClass: 'animate__animated', // default
      offset: 0, // default
      mobile: false, // default
      live: true, // default
    });
    wow.init();
  }, [])

  return (
    <>
      <div className='mainHeader'>
        <Container fluid className='mainNav container-lg'>
          <div className='headerLeft'>
            <div className='navMenu d-flex flex-wrap gap-2'>
              <a href='https://twitter.com/PeaPop_Meme' target='_blank' rel='noreferrer' className='navLink'><img src={twitter} alt='twitter' /></a>
              <a href='https://t.me/PeaPop_Meme' target='_blank' className='navLink' rel='noreferrer'><img src={telegram} alt='telegram' /></a>
              <a href='https://medium.com/@PeaPop' target='_blank' className='navLink' rel='noreferrer'><img src={medium} alt='medium' /></a>
            </div>
          </div>
          <div className='headerRight'>
            <Button className='headerBtn'>Buy Now</Button>
          </div>
        </Container>
      </div>
    </>
  )
}

export default Header