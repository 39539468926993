import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import logo from '../Assets/Images/logo.png';

const Footer = () => {
  return (
    <>
      {/* footerSec start */}
      <div className='footersec'>
        <Container>
          <Row className='justify-content-between align-items-end'>
            <Col lg={3} xl={3} xxl={3} className='text-center'>
              <div className='footerLogo'>
                <img src={logo} alt='peacock' />
              </div>
              <p className='mt-3'>&copy; 2024 PECK. Peacock</p>
            </Col>
            <Col sm={10} md={8} lg={5} xl={5} xxl={4} className='text-center mx-auto my-3 my-lg-0'>
              <h4 className='mb-3 mb-lg-4'>Disclaimer</h4>
              <p>LioMan was made for a fan and has no value. Don't risk money you are afraid of losing. The price may go up or it may go down. We are not responsible for the price of the token.</p>
            </Col>
            <Col lg={2} className='text-end'>
              <p>$peCK On Solana</p>
            </Col>
          </Row>
        </Container>
      </div>
      {/* footerSec end */}
    </>
  )
}

export default Footer