import React from 'react';
import { Accordion, Button, Col, Container, Row } from 'react-bootstrap';
import bannerPck from '../Assets/Images/bannerPck.png';
import peacockTxt from '../Assets/Images/peacockTxt.png';
import navNest from '../Assets/Images/navNest.png';
import coinmarket from '../Assets/Images/coinmarket.png';
import dexscreener from '../Assets/Images/dexscreener.png';
import gotbit from '../Assets/Images/gotbit.png';
import dextools from '../Assets/Images/dextools.png';
import aboutPck from '../Assets/Images/aboutPck.png';
import availableBg from '../Assets/Images/Backgrounds/availableBg.png';
import mexc from '../Assets/Images/mexc.png';
import raydium from '../Assets/Images/raydium.png';
import lbank from '../Assets/Images/lbank.png';
import poloniex from '../Assets/Images/poloniex.png';
import bitmart from '../Assets/Images/bitmart.png';
import bitget from '../Assets/Images/bitget.png';
import jupiter from '../Assets/Images/jupiter.png';
import tokenBg from '../Assets/Images/Backgrounds/tokenBg.png';
import num1 from '../Assets/Images/num1.png';
import num2 from '../Assets/Images/num2.png';
import num3 from '../Assets/Images/num3.png';
import num4 from '../Assets/Images/num4.png';
import commPck from '../Assets/Images/commPck.png';
import twitter from '../Assets/Images/Icons/twitter.png';
import telegram from '../Assets/Images/Icons/telegram.png';
import medium from '../Assets/Images/Icons/medium.png';
import faqPck from '../Assets/Images/faqPck.png';
import CopyToClipboard from 'react-copy-to-clipboard';

const Home = () => {
  const contractAddress = '86WAcadjYakw7zoUbkJVBcPgXJqBxQb6TdbPtcMYDHSm';
  const partnerItems = [coinmarket, dexscreener, gotbit, dextools];
  const availItems = [mexc, raydium, lbank, poloniex, bitmart, bitget, jupiter];
  const tokenData = [{ title: 'Tax', data: '0/0' }, { title: 'MINT & FREEZE', data: 'REVOKED' }, { title: 'LIQUIDITY', data: 'BURNED' }, { title: 'TOTAL SUPPLY', data: '99,000,000.00' }];

  return (
    <>
      {/* bannerSec start */}
      <div className='bannerSec'>
        <Container>
          <div className='bannerHead wow animate__animated animate__pulse'>
            <img src={peacockTxt} alt='PeaPop' className='d-block mx-auto' />
          </div>
          <div className='bannerPck'>
            <img src={bannerPck} alt='PeaPop' className='w-100' />
            <Button>Buy Me</Button>
          </div>
          <div className='bannerNav'>
            <div className='bnrNavLeft'>
              <a href='#aboutus' className='bnrNavLink'>
                <div className='wow animate__animated animate__fadeInUp'>
                  <img src={navNest} alt='' />
                  <span>About Us</span>
                </div>
              </a>
              <a href='#tokenomics' className='bnrNavLink'>
                <div className='wow animate__animated animate__fadeInUp' data-wow-delay='0.2s'>
                  <img src={navNest} alt='' />
                  <span>Tokenomics</span>
                </div>
              </a>
            </div>
            <div className='bnrNavRight'>
              <a href='#howtobuy' className='bnrNavLink'>
                <div className='wow animate__animated animate__fadeInUp' data-wow-delay='0.2s'>
                  <img src={navNest} alt='' />
                  <span>HowToBuy?</span>
                </div>
              </a>
              <a href='#community' className='bnrNavLink'>
                <div className='wow animate__animated animate__fadeInUp'>
                  <img src={navNest} alt='' />
                  <span>Community</span>
                </div>
              </a>
            </div>
          </div>
        </Container>
      </div>
      {/* bannerSec end */}

      {/* partnersec start */}
      <div className='partnerSec'>
        <Container>
          <h2 className='mainTitle mb-4 wow animate__animated animate__flipInX'>Our Partners</h2>

          <Row>
            {partnerItems.map((item, id) => {
              return (
                <Col md={6} lg={3} key={id}>
                  <div className='partnerDiv mb-3 mb-lg-0 wow animate__animated animate__flipInX' data-wow-delay={`${id / 10}s`}>
                    <img src={item} alt='' className='d-block mx-auto' />
                  </div>
                </Col>
              )
            })}
          </Row>
        </Container>
      </div>
      {/* partnersec end */}

      {/* aboutSec start */}
      <div className='aboutSec' id='aboutus'>
        <Container>
          <Row>
            <Col lg={5}>
              <div className='aboutImg wow animate__animated animate__fadeInLeft'>
                <img src={aboutPck} alt='' className='d-block mx-auto w-100' />
              </div>
            </Col>
            <Col lg={7}>
              <div className='aboutTxt wow animate__animated animate__fadeInRight'>
                <h2 className='mainTitle'>About $PEPOP</h2>

                <p>Welcome To $PEPOP, The Jungle Addition To The Solana Blockchain! Our Small Yet Mighty Peacock, With A Hint Of The Frog, Brings a Fresh Breeze Of Creativity To The Degen World Of Solana. As The Newest And Freshest Meme Token, $PEPOP Is Here Spice Things Up. Join Us In Bringing Warmth And Fathering Liquidity To The Frosty Solana Chain.</p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      {/* aboutSec end */}

      {/* availableSec start */}
      <div className='availableSec'>
        <Container>
          <h2 className='mainTitle mb-3 mb-sm-5 wow animate__animated animate__flipInX'>Available On</h2>

          <Row className='justify-content-center'>
            {availItems.map((item, id) => {
              return (
                <Col md={6} lg={3} key={id}>
                  <div className='availableDiv position-relative wow animate__animated animate__bounce' data-wow-delay={`${id / 10}s`}>
                    <img src={availableBg} alt='' className='availBg' />
                    <img src={item} alt='' className='availImg' />
                  </div>
                </Col>
              )
            })}
          </Row>
        </Container>
      </div>
      {/* availableSec end */}

      {/* tokenSec start */}
      <div className='tokenSec' id='tokenomics'>
        <Container>
          <h2 className='mainTitle mb-4 wow animate__animated animate__flipInX'>PeaPop Tokenomics</h2>

          <div className='contractAddress mb-5 wow animate__animated animate__jackInTheBox'>CONTRACT :<span className='mx-3 my-1 my-sm-0'>{contractAddress}</span>
            <CopyToClipboard text={contractAddress}>
              <Button>Copy</Button>
            </CopyToClipboard>
          </div>

          <Row>
            {tokenData.map((item, id) => {
              return (
                <Col sm={6} lg={3} key={id}>
                  <div className='tokenDiv position-relative wow animate__animated animate__zoomIn'>
                    <img src={tokenBg} alt='' />
                    <div className='tokenTxt'>
                      <p>{item.title}</p>
                      <h2>{item.data}</h2>
                    </div>
                  </div>
                </Col>
              )
            })}
          </Row>
        </Container>
      </div>
      {/* tokenSec end */}

      {/* buySec start */}
      <div className='buySec position-relative' id='howtobuy'>
        <Container>
          <h2 className='mainTitle wow animate__animated animate__flipInX'>How To Buy?</h2>
          <Row>
            <Col lg={6} xl={5} className='mb-4 mb-lg-0'>
              <div className='buyDiv wow animate__animated animate__jackInTheBox'>
                <div className='buyNum'>
                  <img src={num1} alt='' />
                </div>
                <div className='buyTxt'>
                  <h4 classname='position-relative'>DOWNLOAD PHANTOM EXTENTION</h4>
                  <p>Download And Install Phantom On The Appstore or Alternatively If You're On Desktop Download And Install The Browser Extention.</p>
                </div>
              </div>
            </Col>
            <Col lg={6} xl={5} className='mb-4 mb-lg-0'>
              <div className='buyDiv wow animate__animated animate__jackInTheBox'>
                <div className='buyNum'>
                  <img src={num2} alt='' />
                </div>
                <div className='buyTxt'>
                  <h4 classname='position-relative'>SECURE YOUR SOL</h4>
                  <p>Now All You've Got To Do Is Buy Your SOL in The PHANTOM APP Or Alternatively You Can Use An Exchange And Deposit Using Your Wallet Address.</p>
                </div>
              </div>
            </Col>
            <Col lg={6} xl={5} className='ms-auto mb-4 mb-lg-0'>
              <div className='buyDiv wow animate__animated animate__jackInTheBox'>
                <div className='buyNum'>
                  <img src={num3} alt='' />
                </div>
                <div className='buyTxt'>
                  <h4 classname='position-relative'>BUY SOME $PEPOP</h4>
                  <p>Now Go To Raydium And Paste The PeaPop Contract Address To Swap You SOL.</p>
                </div>
              </div>
            </Col>
            <Col lg={6} xl={5} className='mb-4 mb-lg-0'>
              <div className='buyDiv wow animate__animated animate__jackInTheBox'>
                <div className='buyNum'>
                  <img src={num4} alt='' />
                </div>
                <div className='buyTxt'>
                  <h4 classname='position-relative'>ADD TO YOUR WALLET</h4>
                  <p>Now You're All Se! Welcome Aboard The Next Rocket To The Moon, Just Import The Contract Address To Vire You're Holdings And You Can Track Your Earnings.</p>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
        <div className='buySecBg'>How To Buy?</div>
      </div>
      {/* buySec end */}

      {/* communitysec start */}
      <div className='communitySec' id='community'>
        <Container className='commRow'>
          <Row className='justify-content-center align-items-center'>
            <Col lg={4} xl={4}>
              <div className='commImg wow animate__animated animate__slideInLeft'>
                <img src={commPck} alt='' className='w-100' />
              </div>
            </Col>
            <Col lg={8} xl={7}>
              <div className='commTxt wow animate__animated animate__slideInRight'>
                <h2 className='mainTitle'>Slide Into Our Community!</h2>
                <p>Join Our Community And Follow Us For The Latest Updates  And A Warm Welcome From Our Friendly Community.</p>
                <div className='navMenu d-flex flex-wrap justify-content-center gap-2'>
                  <a href='https://twitter.com/PeaPop_Meme' target='_blank' rel='noreferrer' className='navLink'><img src={twitter} alt='twitter' /></a>
                  <a href='https://t.me/PeaPop_Meme' target='_blank' className='navLink' rel='noreferrer'><img src={telegram} alt='telegram' /></a>
                  <a href='https://medium.com/@PeaPop' target='_blank' className='navLink' rel='noreferrer'><img src={medium} alt='medium' /></a>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      {/* communitysec end */}

      {/* faqSec start */}
      <div className='faqSec'>
        <h2 className='mainTitle mb-5 wow animate__animated animate__flipInX'>FAQ's</h2>

        <Container>
          <Row className='align-items-center'>
            <Col lg={7} xl={7}>
              <div className='faqDiv position-relative z-1'>
                <Accordion>
                  <Accordion.Item eventKey="0" className='wow animate__animated animate__fadeInLeft' data-wow-delay='0.1s'>
                    <Accordion.Header>Who Is $PEPOP?</Accordion.Header>
                    <Accordion.Body className='pt-0'>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="1" className='wow animate__animated animate__fadeInLeft' data-wow-delay='0.2s'>
                    <Accordion.Header>What Chain Is $PEPOP On?</Accordion.Header>
                    <Accordion.Body className='pt-0'>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="2" className='wow animate__animated animate__fadeInLeft' data-wow-delay='0.3s'>
                    <Accordion.Header>How Can I Buy $PEPOP?</Accordion.Header>
                    <Accordion.Body className='pt-0'>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="3" className='wow animate__animated animate__fadeInLeft' data-wow-delay='0.4s'>
                    <Accordion.Header>What Is The Predications Of $PEPOP Price In The Future?</Accordion.Header>
                    <Accordion.Body className='pt-0'>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="4" className='wow animate__animated animate__fadeInLeft' data-wow-delay='0.5s'>
                    <Accordion.Header>Why Does $PEPOP Look Like Pepe?</Accordion.Header>
                    <Accordion.Body className='pt-0'>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </div>
            </Col>
            <Col lg={5} xl={5}>
              <div className='faqImg position-relative wow animate__animated animate__fadeInRight z-0'>
                <img src={faqPck} alt='' className='w-100' />
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      {/* faqSec end */}
    </>
  )
}

export default Home